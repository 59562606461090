.Rodape {
  text-align: center;
  padding: 2rem 0;
  background-color: var(--darker-blue);
  color: var(--light-blue);
  font-weight: 300;
  font-size: 1.2rem;
}
.Rodape strong {
  font-weight: 600;
}
