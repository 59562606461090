.cabecalhoContainer {
  overflow: hidden;
}
.logo {
  width: 3.5rem;
}
li {
  cursor: pointer;
}

.cabecalho {
  z-index: 1000;
  position: fixed;
  width: 100%;
  height: 6rem;
  background-color: var(--dark-blue);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}
.logo {
  color: var(--light-blue);
  font-size: 4rem;
  font-weight: 800;
  text-decoration: none;
}

.listaLinks {
  display: none;
}
.listaLinks a {
  text-decoration: none;
  color: var(--light-blue);
  font-size: 2rem;
  padding: 1rem;
}
.listaLinks a {
  position: relative;
  text-decoration: none;
  color: var(--light-blue);
  font-size: 2rem;
  padding: 1rem;
  overflow: hidden;
}

.listaLinks a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background-color: var(--light-blue);
  bottom: 0;
  left: 50%;
  transition: width 0.3s ease, left 0.3s ease;
}

.listaLinks a:hover::after {
  width: 100%;
  left: 0;
}

.mobileMenuContent {
  position: absolute;
  top: 6rem;
  left: 0;
  background-color: var(--dark-blue);
  height: calc(100vh - 6rem);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vh;
  animation: slideInFromLeft 0.5s ease-out;
}

.mobileMenuContent a {
  text-decoration: none;
  color: var(--light-blue);
  font-size: 4.5rem;
}
.mobileMenuContent li:hover {
  transform: scale(1.2);
}
.mobileMenuContent li {
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.menuHidden {
  display: flex;
  animation: slideOutToLeft 0.5s ease-out forwards;
}

.menuVisible {
  display: flex;
  animation: slideInFromLeft 0.5s ease-out forwards;
}
@media (min-width: 600px) {
  .listaLinks {
    display: flex;
  }
  .hamburgerWrapper {
    display: none;
  }
}
@keyframes slideInFromLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideOutToLeft {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}
