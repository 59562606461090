.listaProjetos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3vw;
}

.cardProjeto {
  background-color: white;
  align-self: flex-start;
  cursor: pointer;
  min-width: 23rem;
  max-width: 45rem;
  width: 40%;
  border-radius: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: scale(1);
  transition: transform 0.5s;
}
.cardProjeto:hover {
  transform: scale(1.05);
  transition: transform 0.3s;
}

.imgProjeto {
  background-color: var(--lightest-gray);
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  height: 100%;
}
.infoProjeto {
  padding: 2rem;
  align-self: center;
}
.titleProjeto {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  color: var(--darkerest-blue);
}
.textProjeto {
  margin-top: 1.5rem;
  line-height: 120%;
  text-align: center;
  font-size: 1.5rem;
  color: var(--darker-blue);
}

@media (max-width: 580px) {
  .listaProjetos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
  }

  .cardProjeto {
    width: 70%;
    align-self: center;
  }
}
